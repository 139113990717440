var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    { staticClass: "processBox", attrs: { loading: _vm.waiting } },
    [
      _c("div", { staticClass: "topBox" }, [
        _c("div", { staticClass: "leftTopBox" }, [
          _c("img", {
            staticClass: "wanderImg",
            attrs: { src: require("@/assets/images/wander.png") },
          }),
          _c(
            "div",
            { staticClass: "leftTopTitle" },
            [
              _c("div", [
                _vm._v(" " + _vm._s(_vm.process.processDefinitionName)),
              ]),
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "申请信息", name: "first" },
                  }),
                  _c("el-tab-pane", {
                    attrs: { label: "流转信息", name: "second" },
                  }),
                  _c("el-tab-pane", {
                    attrs: { label: "流程跟踪", name: "third" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm.type != "view"
          ? _c(
              "div",
              { staticClass: "rightTopBox" },
              [
                _c("wf-button", {
                  attrs: {
                    loading: _vm.submitLoading,
                    "button-list": _vm.buttonList,
                    process: _vm.process,
                    comment: _vm.comment,
                  },
                  on: {
                    examine: _vm.handleOpen,
                    "temporary-save": _vm.handleTemporary,
                    "user-select": _vm.handleUserSelect,
                    print: _vm.handlePrint,
                    rollback: _vm.handleRollbackTask,
                    terminate: _vm.handleTerminateProcess,
                    withdraw: _vm.handleWithdrawTask,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "statusIcon" },
          [
            _vm.process.processIsFinished === "reject"
              ? [
                  _vm.process.status === "todo"
                    ? _c("i", [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/statusIcon/submit.png"),
                            alt: "",
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm.process.status === "done"
                    ? _c("i", [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/statusIcon/refuse.png"),
                            alt: "",
                          },
                        }),
                      ])
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.process.processIsFinished === "unfinished"
              ? [
                  _vm.process.status === "todo"
                    ? _c("i", [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/statusIcon/audit.png"),
                            alt: "",
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm.process.status === "done"
                    ? _c("i", [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/statusIcon/audit.png"),
                            alt: "",
                          },
                        }),
                      ])
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.process.processIsFinished === "finished"
              ? [
                  _vm.process.status === "todo"
                    ? _c("i", [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/statusIcon/audit.png"),
                            alt: "",
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm.process.status === "done"
                    ? _c("i", [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/statusIcon/pass.png"),
                            alt: "",
                          },
                        }),
                      ])
                    : _vm._e(),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c(
        "div",
        { staticClass: "middleBox" },
        [
          _c(
            "div",
            { staticClass: "middleBorder" },
            [
              _vm.process.formUrl && _vm.activeName == "first"
                ? _c("componentKey", {
                    tag: "component",
                    staticClass: "detailBox",
                  })
                : _vm._e(),
              _vm.activeName == "second"
                ? _c("wf-flow", {
                    staticStyle: { height: "100%", overflow: "auto" },
                    attrs: { flow: _vm.flow },
                  })
                : _vm._e(),
              _vm.activeName == "third"
                ? _c("wf-design", {
                    ref: "bpmn",
                    staticStyle: { height: "100%" },
                    attrs: { options: _vm.bpmnOption },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("wf-examine-form", {
            ref: "examineForm",
            staticClass: "formBox",
            attrs: {
              comment: _vm.comment,
              process: _vm.process,
              type: _vm.type,
              hideApproval: _vm.hideApproval,
            },
            on: {
              "update:comment": function ($event) {
                _vm.comment = $event
              },
              "user-select": _vm.handleUserSelect,
            },
          }),
        ],
        1
      ),
      _c("process-user-dialog", {
        ref: "processUser",
        on: { getUserSelection: _vm.headConfirm },
      }),
      _c("user-select", {
        ref: "user-select",
        attrs: {
          "check-type": _vm.checkType,
          "default-checked": _vm.defaultChecked,
        },
        on: { onConfirm: _vm.handleUserSelectConfirm },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }